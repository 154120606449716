export default {
    apiGateway: {
        REGION: 'eu-west-1',
        URL: 'https://f1qfszn0ik.execute-api.eu-west-1.amazonaws.com/dev',
        // PATH is just used to refer to api within the app when initialising and using amplify API
        PATH: 'sentinel-api',
    },
    cognito: {
        REGION: 'eu-west-1',
        USER_POOL_ID: 'eu-west-1_XoDK4Po13',
        APP_CLIENT_ID: '4pnmirbgrianeca4kcge4kfsto',
        IDENTITY_POOL_ID: 'eu-west-1:419d8299-4104-4f6e-a8af-efd86f7806a3'
    },
    s3: {
        REGION: 'eu-west-1',
        BUCKET: 'sentinel-user-bucket'
    }
};