import React, { Component } from 'react'
import { Auth } from 'aws-amplify'

import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Form,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Container,
  Button,
  FormGroup
} from 'reactstrap'

import logo from '../../resources/logo/sentinel-cover-logo-400.png'

class LoginForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			password: '',
			emailRegex: null,
			passwordRegex: null,
		}
	}

	checkEmail(input) {
		var regexEmail = /^(([^<>()\]\\.,;:\s@']+(\.[^<>()\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (regexEmail.test(input)) {
			this.setState({emailRegex: 'success'});
		}
		else {
			this.setState({emailRegex: 'warning'});
		}
	}

	checkPassword(input) {
		var regexPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;

		if (regexPassword.test(input)) {
			this.setState({passwordRegex: 'success'});
		}
		else {
			this.setState({passwordRegex: 'warning'});
		}
	}

	validateForm() {
		if (this.state.emailRegex === 'success' && this.state.passwordRegex === 'success') {
			return true;
		}
		else {
			return false;
		}
	}

	handleEmailChange(email) {
		this.setState({
			email: email
		})
	}

	handlePasswordChange(password) {
		this.setState({
			password: password
		})
	}

	handleSubmit = async event => {
		event.preventDefault();
		this.login();
	}

	async login() {
		try {
			const user = await Auth.signIn(this.state.email, this.state.password)
			console.log(user)
			
			window.location.replace('/profile')
		} catch(err) {
			if (err.code === 'UserNotConfirmedException') {
				alert('You must confirm your account in order to log in. Please check your email for a confirmation link');
			}
			else if (err.code === 'UserNotFoundException') {
				alert('An account with this email address has not yet been registered.');
			}
			else if (err.code === 'NotAuthorizedException') {
				alert('The email address or password you have entered is incorrect');
			}
			console.log(err)
		}
	}

	render() {
		return (
			<Form className='form' onSubmit={this.handleSubmit}>
				<Card className="card-login">
                    <CardHeader>
                    	<div className="logo-container">
                        	<img width='400px' src={logo} alt="now-logo" />
                      	</div>
                    </CardHeader>

                    <CardBody>
                      	<FormGroup>
	                        <Input
	                          	autoComplete='new-email'
	                          	type="email"
	                          	autoFocus={true}
	                          	placeholder="Email..."
	                          	value={this.state.email}
							  	onChange={e => this.handleEmailChange(e.target.value)}
	                        />
                      	</FormGroup>

                      	<FormGroup>
                        	<Input
	                          	autoComplete='new-password'
	                          	type="password"
	                          	placeholder="Password..."
	                          	value={this.state.password}
							  	onChange={e => this.handlePasswordChange(e.target.value)}
                        	/>
                      	</FormGroup>
                    </CardBody>

                    <CardFooter>
                      	<Button
                        	color="warning"
                        	block
                        	type="submit"
                        	className="btn-round mb-3"
                      	>
                        	Log In
                      	</Button>
                    </CardFooter>
                </Card>
	        </Form>
	    )
	}
}

export default LoginForm