import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import './core/index.css'
import App from './core/App'
import * as serviceWorker from './core/serviceWorker'

import {BrowserRouter} from 'react-router-dom'

// Import Amplify configuration from backend
import Amplify, { Auth } from 'aws-amplify'
import { Authenticator } from 'aws-amplify-react'
import config from './config/api/Config'

// Theme css
import './resources/scss/paper-dashboard.css'
import "bootstrap/dist/css/bootstrap.css";
import "./resources/scss/paper-dashboard.scss";


Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID
    },
    API: {
        endpoints: [
            {
                name: config.apiGateway.PATH,
                endpoint: config.apiGateway.URL,
                region: config.apiGateway.REGION
            },
        ]
    },
    Storage: {
        region: config.s3.REGION,
        bucket: config.s3.BUCKET,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
    }
});

class AppWithAuth extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            // Put the App component inside the authenticator component and only render once correct authState is reached
           <Authenticator hideDefault={true}>
                <App />
            </Authenticator>
        );
    }
}

ReactDOM.render(<BrowserRouter><AppWithAuth /></BrowserRouter>, document.getElementById('root'));



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
