/* LOGGED OUT ROUTER */
import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Header from '../../components/tim/GuestHeader'
import Login from '../../scenes/loggedout/login'

const Main = () => (
	<div>
		<Header />
	    <div className="wrapper wrapper-full-page">
	        <div className="full-page section-image">
		        <Switch>
		            <Route component={Login}/>
		        </Switch>
		    </div>
		</div>
	</div>
);

export default Main