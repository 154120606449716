import React, { Component } from 'react'
import LoginForm from '../../components/doda/LoginForm'
//import ForgotPassword from '../../components/general/ForgotPassword'

import {
	Container,
	Row,
	Col,
} from 'reactstrap'

import '../Scene.css'

class Login extends Component {
	constructor(props) {
		super(props)
	}

	componentDidMount() {
    	document.body.classList.toggle("login-page")
  	}

  	componentWillUnmount() {
    	document.body.classList.toggle("login-page")
  	}

	render() {
		return (
	        <div className="login-page">
        		<Container>
          			<Row>
            			<Col className="ml-auto mr-auto" lg="4" md="6">
                    		<LoginForm history={this.props.history} />
                    		{/*<ForgotPassword />*/}
                    	</Col>
                    </Row>
                </Container>
                <div
			        className="full-page-background"
			        style={{backgroundImage: `url(${require("../../resources/images/buildings.png")})`}}
			    />
            </div>
	    )
	}
}

export default Login