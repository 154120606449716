import React, { Component } from 'react'
import apiclient from '../config/api/AdminClient'
import {
	Row,
	Col,
	Card,
	CardBody,
	CardFooter,
	CardTitle,
	CardHeader,
	Button,
	UncontrolledTooltip,
	Table
} from 'reactstrap'
import UserModal from '../components/custom/UserModal'

class Dashboard extends Component {
	constructor(props) {
		super(props)
		this.state = {
			totals: {},
			users: {},
			loading: true,
			userModal: false,
			user: null,
		}

		this.apiclient = new apiclient()
		this.renderUsers = this.renderUsers.bind(this)
		this.handleClose = this.handleClose.bind(this)
	}

	async componentWillMount() {
		this.refreshUsers()
	}

	async refreshUsers() {
		let users = await this.apiclient.getAllUsers()
		console.log(users)

		let totals = await this.apiclient.getAnalyticsTotals()
		console.log(totals)

		this.setState({
			users: users,
			totals: totals,
			loading: false
		})
	}

	openUserModal(user) {
		console.log(user)
		this.setState({
			user: user,
			userModal: true,
		})
	}

	handleClose() {
		this.setState({
			userModal: false,
			user: null,
		})
	}

	async confirmUser(userId) {
		let user = await this.apiclient.confirmUser(userId)
		console.log(user)

		this.refreshUsers()
	}

	renderUsers() {
		let users = this.state.users.map((user, i) => {
			return (
				<tr key={i} className={(user.confirmed) ? 'confirmed' : 'unconfirmed'}>
		        	<td>{user.firstName + ' ' + user.lastName}</td>
		        	<td>{user.email}</td>
		        	<td className='text-center'>{user.country}</td>
		        	<td className='text-right'>
		        		{!user.confirmed &&
		        			<div style={{display: 'initial'}}>
			        			<Button
				            		className="btn-icon"
				            		color="success"
				            		id="tooltip2644532"
				            		size="sm"
				            		type="button"
				            		onClick={() => this.confirmUser(user.userId)}
				          		>
				            		<ion-icon name="checkmark-circle" className='card-icon'></ion-icon>
				          		</Button>{" "}
						        <UncontrolledTooltip
						            delay={0}
						            target="tooltip2644532"
						        >
						            Confirm User
						        </UncontrolledTooltip>
					        </div>
		        		}
		          		<Button
		            		className="btn-icon"
		            		color="info"
		            		id="tooltip264453216"
		            		size="sm"
		            		type="button"
		            		onClick={() => this.openUserModal(user)}
		          		>
		            		<ion-icon name="card" className='card-icon'></ion-icon>
		          		</Button>{" "}
				        <UncontrolledTooltip
				            delay={0}
				            target="tooltip264453216"
				        >
				            View Cards
				        </UncontrolledTooltip>
		        	</td>
		      	</tr>
			)
		})

		return users
	}

	render() {
		return (
			!this.state.loading &&
	        <div className='content'>
	        	{this.state.user &&
		        	<UserModal 
		        		isOpen={this.state.userModal}
		        		handleClose={() => this.handleClose()}
		        		user={this.state.user}
		        	/>
		        }

	        	<Row>
	        		<Col md='4'>
	        			<Card className="card-stats">
			                <CardBody>
			                  	<Row>
				                    <Col md="4" xs="5">
				                      	<div className="icon-big text-center icon-warning">
				                        	<i className="nc-icon nc-single-02 text-warning" />
				                      	</div>
				                    </Col>
				                    <Col md="8" xs="7">
				                      	<div className="numbers">
				                        	<p className="card-category">Users</p>
				                        	<CardTitle tag="p">{this.state.totals.users}</CardTitle>
				                        	<p />
				                      	</div>
				                    </Col>
			                  	</Row>
			                </CardBody>
			            </Card>
	        		</Col>
	        		<Col md='4'>
	        			<Card className="card-stats">
			                <CardBody>
			                  	<Row>
				                    <Col md="4" xs="5">
				                      	<div className="icon-big text-center icon-warning">
				                        	<i className="nc-icon nc-credit-card text-success" />
				                      	</div>
				                    </Col>
				                    <Col md="8" xs="7">
				                      	<div className="numbers">
				                        	<p className="card-category">Transactions</p>
				                        	<CardTitle tag="p">{this.state.totals.transactions}</CardTitle>
				                        	<p />
				                      	</div>
				                    </Col>
			                  	</Row>
			                </CardBody>
			            </Card>
	        		</Col>
	        		<Col md='4'>
	        			<Card className="card-stats">
			                <CardBody>
			                  	<Row>
				                    <Col md="4" xs="5">
				                      	<div className="icon-big text-center icon-warning">
				                        	<i className="nc-icon nc-simple-remove text-danger" />
				                      	</div>
				                    </Col>
				                    <Col md="8" xs="7">
				                      	<div className="numbers">
				                        	<p className="card-category">Rejected</p>
				                        	<CardTitle tag="p">{this.state.totals.rejected}</CardTitle>
				                        	<p />
				                      	</div>
				                    </Col>
			                  	</Row>
			                </CardBody>
			            </Card>
	        		</Col>
	        	</Row>
	        	<Row className='apk-button-container'>
	        		<a className='apk-button' href='https://s3-eu-west-1.amazonaws.com/sentinel-apk-bucket/sentinel.apk'>Download Android App</a>
	        	</Row>
	        	<Row>
	        		<Col md="12">
						<Card>
							<CardHeader>
					  			<CardTitle tag="h4">Users</CardTitle>
							</CardHeader>
							
							<CardBody>
					  			<Table responsive>
					    			<thead className="text-primary">
					      				<tr>
									        <th>Name</th>
									        <th>Email</th>
									        <th className='text-center'>Country</th>
									        <th className='text-right'>Actions</th>
					      				</tr>
					    			</thead>
					    
					    			<tbody>
					      				{this.renderUsers()}
					    			</tbody>
					  			</Table>
							</CardBody>
						</Card>
					</Col>
	        	</Row>
	        </div>
	    )
	}
}

export default Dashboard