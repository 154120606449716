import React, {Component} from 'react'
import {
	Modal, 
	ModalBody, 
	ModalHeader, 
	ModalFooter, 
	Button, 
	Label, 
	Row, 
	Col,
	FormGroup,
	Input
} from 'reactstrap'
import {withRouter} from 'react-router-dom'
import apiclient from '../../config/api/AdminClient'

class UserModal extends Component{
	constructor(props){
		super(props)
		this.state = {
			cards: [],
			card: null,
			stage: 'cards',
			value: '',
			vendor: '',
			location: '',
		}
		this.apiclient = new apiclient()
		this.handleClose = this.handleClose.bind(this)
		this.updateValue = this.updateValue.bind(this)
		this.updateVendor = this.updateVendor.bind(this)
		this.updateLocation = this.updateLocation.bind(this)
	}

	async componentWillMount() {
		let cards = await this.apiclient.getCardsByUser(this.props.user.userId)
		console.log(cards)

		this.setState({
			cards: cards
		})
	}

	showAddTransaction(card) {
		console.log(card)
		this.setState({
			card: card,
			stage: 'transaction'
		})
	}

	async addTransaction() {
		let card = this.state.card

		try {
			let transaction = await this.apiclient.simulateTransaction(card.userId, card.cardId, this.state.value, this.state.vendor, this.state.location)
			console.log(transaction)

			if (transaction === 'Card Frozen') {
				alert('Transaction could not be sent. Merchant informed that card is stolen.')
			}

			this.setState({
				stage: 'cards',
				card: null,
				value: '',
				vendor: '',
				location: '',
			})
		} catch (e) {
			console.log(e)
			alert('Failed to send transaction')
		}
	}

	renderCards() {
		let cards = this.state.cards.map((card, i) => {
			return (
				<div className={(card.frozen) ? 'frozen-container' : 'card-container'} key={i}>
					<div className='card-container-left'>
						<h2>{card.issuedBy} - {card.cardNo.slice(-4)}</h2>
						<h3>{card.cardType.charAt(0).toUpperCase() + card.cardType.slice(1)} Card</h3>
					</div>
					<div className='card-container-middle'>
						<div className='threshold-container'>
							<h3>€{card.threshold}</h3>
							{card.stolen && 
								<p>Stolen</p>
							}
						</div>
					</div>
					<div className='card-container-right'>
						<Button className='card-button' color='secondary' onClick={() => this.showAddTransaction(card)}>
                            Simulate Transaction
                        </Button>
					</div>
				</div>
			)
		})

		if (cards.length > 0) {
			return cards
		}
		else {
			return (<p style={{textAlign: 'center'}}>No cards added</p>)
		}
	}

	renderTransactionForm() {
		return (
			<div className='transaction-form'>
				<FormGroup>
					<Input 
						value={this.state.value} 
						type="number" 
						onChange={this.updateValue}
						placeholder={'Value'}
					/>
				</FormGroup>

				<FormGroup>
					<Input 
						value={this.state.vendor} 
						type="text" 
						onChange={this.updateVendor}
						placeholder={'Vendor'}
					/>
				</FormGroup>

				<FormGroup>
					<Input 
						value={this.state.location} 
						type="text" 
						onChange={this.updateLocation}
						placeholder={'Location'}
					/>
				</FormGroup>

				<Button className='card-button' color='primary' onClick={() => this.addTransaction()}>
                    Submit
                </Button>
			</div>
		)
	}

	updateValue(event) {
		this.setState({
			value: event.target.value
		})
	}

	updateVendor(event) {
		this.setState({
			vendor: event.target.value
		})
	}

	updateLocation(event) {
		this.setState({
			location: event.target.value
		})
	}

    handleClose() {
    	this.setState({
    		cards: []
    	})
    	this.props.handleClose()
    }

	render(){
		return(
			<Modal isOpen={this.props.isOpen} toggle={this.handleClose} size='lg'>
				<ModalHeader>
					<p className='description'>{this.props.user.firstName + ' ' + this.props.user.lastName}</p>
				</ModalHeader>

				<ModalBody className='modal-row-spacer'>
					{this.state.stage === 'cards' &&
						<div className='cards'>
							{this.renderCards()}
						</div>
					}
					{this.state.stage === 'transaction' &&
						<div className='cards'>
							{this.renderTransactionForm()}
						</div>
					}				
				</ModalBody>

				<ModalFooter className='modal-footer-multi'>
					<Button color='neutral' onClick={() => this.handleClose()}>Close</Button>
				</ModalFooter>
			</Modal>
		)
	}
}

export default withRouter(UserModal)