import React, { Component } from 'react'
import AdminRouter from '../config/routing/AdminRouter'
import GuestRouter from '../config/routing/GuestRouter'

import { Auth } from 'aws-amplify'

console.log = function() {}

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accountType: null,
            loading: false
        }
    }

    async componentWillMount() {
        const info = await Auth.currentUserInfo()
        console.log(info)

        if (info) {
            this.setState({
                accountType: info.attributes['custom:account_type'],
                loading: false
            })

            // Check has details and pass info down if so
        }
        else {
            console.log('No current user info')

            this.setState({
                loading: false
            })
        }
    }

    logout() {
        console.log('logout')
        Auth.signOut().then((user) => {
            window.location.replace('/');
            console.log(user)
        }).catch(err => 
            console.log(err)
        );
    }

    render() {
        return (
            !this.state.loading &&
            <div>
                {this.props.authState === 'signedIn' ? (
                    <div>
                        {this.state.accountType === 'admin' &&
                            <AdminRouter 
                                accountType={this.state.accountType}
                                details={{frozen: false}}
                            />
                        }
                    </div>
                ) : (
                    <GuestRouter />
                )}
            </div>
        );
    }
}

export default App