/* ACADEMIC ROUTER */
import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Header from '../../components/tim/UserHeader'
import Sidebar from '../../components/tim/Sidebar'
import Dashboard from '../../scenes/dashboard'

const routes = [
	{
		path: "/",
		name: "Dashboard",
		icon: "nc-icon nc-single-02",
	},
];

class Main extends Component {
	constructor(props) {
        super(props)
    }

	render() {
		return (
			<div className='wrapper'>
				<Sidebar
					routes={routes}
		          	bgColor={'black'}
		          	activeColor={'info'}
		          	hasDetails={false}
		          	accountType={'admin'}
		          	details={this.props.details}
		        />
		        <div className="main-panel">
			        <Header />
			        <Switch>
			            <Route component={Dashboard} />
			        </Switch>
		        </div>
			</div>
		)
	}
}

export default Main