import { Component } from 'react'
import { Auth, API, Storage } from 'aws-amplify'
import config from './Config'
import uuid from 'uuid'

const apiPath = config.apiGateway.PATH

class AdminClient extends Component {

	getAnalyticsTotals() {
		return API.post(apiPath, '/analytics/admin-totals')
	}

	getAllUsers() {
		return API.post(apiPath, '/users/admin-all')
	}

	confirmUser(userId) {
		let data = {
			userId: userId
		}

		return API.post(apiPath, '/users/admin-confirm', {
			body: data
		})
	}

	getCardsByUser(userId) {
		let data = {
			userId: userId
		}

		return API.post(apiPath, '/cards/admin-get', {
			body: data
		})
	}

	simulateTransaction(userId, cardId, value, vendor, location) {
		let data = {
			userId: userId,
			cardId: cardId,
			value: value,
			vendor: vendor,
			location: location,
		}

		return API.post(apiPath, '/transactions/admin-add', {
			body: data
		})
	}
}

export default AdminClient